<template>
  <div>
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <div id="displayCard">
      <v-card min-width="100%">
        <div class="profile">
          <h1 v-if="$screen.width < 900" style="padding: 16px 16px 16px 16px">
            {{ tabArray[tab].name }}
          </h1>
          <v-tabs v-model="tab" hide-slider :vertical="$screen.width > 900">
            <v-tab key="basic" :ripple="false">
              {{ $t("profileNav.Basic") }}
            </v-tab>
            <v-tab key="personal" :ripple="false">{{ $t("profileNav.Personal") }}</v-tab>

            <v-tab key="social" :ripple="false">{{ $t("profileNav.Social") }}</v-tab>

            <v-tab-item key="basic" transition="none" reverse-transition="none"><ProfileBasic /> </v-tab-item>
            <v-tab-item key="personal" transition="none" reverse-transition="none"><ProfilePersonal /> </v-tab-item>
            <v-tab-item key="social" transition="none" reverse-transition="none"><profile-social /> </v-tab-item>
          </v-tabs>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import MessageBox from "@/components/Common/MessageBox";
import ProfileBasic from "@/components/Platform/ProfileBasic";
import ProfilePersonal from "@/components/Platform/ProfilePersonal";
import PlatformLayout from "@/layouts/Platform";
import ProfileSocial from "../../components/Platform/ProfileSocial.vue";

export default {
  name: "Profile",
  components: {
    MessageBox,
    ProfileBasic,
    ProfilePersonal,
    ProfileSocial
  },
  data: (vm) => ({
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    tabArray: [
      {
        key: "basic",
        name: vm.$t("profileNav.Basic")
      },
      {
        key: "personal",
        name: vm.$t("profileNav.Personal")
      },
      {
        key: "social",
        name: vm.$t("profileNav.Social")
      }
    ]
  }),
  created() {
    this.$emit(`update:layout`, PlatformLayout);
    if (Object.hasOwnProperty.call(this.$store.state.userProfile, "language")) {
      this.language = this.$store.state.userProfile.language;
    }
  },
  computed: {
    tab: {
      get: function () {
        if (!this.$store.state.profilePage) {
          return 0;
        }
        let tab = this.tabArray.findIndex((tab) => {
          return tab.key === this.$store.state.profilePage;
        });
        return tab;
      },
      // setter
      set: function (newValue) {
        this.$store.commit("profilePageChange", this.tabArray[newValue].key);
      }
    }
  }
};
</script>
