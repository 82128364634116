<template>
  <v-row>
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <confirm-dlg ref="confirm" />

    <v-col cols="12" md="9">
      <div class="editField">
        <div id="verify">
          <form>
            <v-text-field
              outlined=""
              v-model="oldPassword"
              :append-icon="show0 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show0 ? 'text' : 'password'"
              name="input-8-2"
              :label="$t('profileBasicCurrentPassword')"
              value=""
              class="input-group--focused"
              @click:append="show0 = !show0"
              data-cy="oldPassword"
              dense
            ></v-text-field>
            <v-text-field
              outlined=""
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[
                rules.required,
                rules.min,
                rules.containsUppercase,
                rules.containsLowercase,
                rules.containsNumber
              ]"
              :error-messages="pwErrors"
              :type="show1 ? 'text' : 'password'"
              name="input-8-2"
              :label="$t('profileBasicPassword')"
              value=""
              class="input-group--focused"
              @click:append="show1 = !show1"
              dense
              data-cy="password"
            ></v-text-field>

            <v-text-field
              outlined=""
              v-model="rePassword"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, pwMatch]"
              :type="show2 ? 'text' : 'password'"
              name="input-8-2"
              :label="$t('signPasswordConfirm')"
              value=""
              class="input-group--focused"
              @click:append="show2 = !show2"
              dense
              data-cy="rePassword"
            ></v-text-field>
          </form>
        </div>
      </div>
    </v-col>
    <v-col>
      <div class="buttonLayout">
        <v-btn depressed rounded width="160px" large @click="$emit('close')" data-cy="passwordCancel">{{
          $t("dialogButton.cancel")
        }}</v-btn>
        <v-btn depressed rounded color="primary" width="160px" large @click="passwordDialog" data-cy="passwordSave">{{
          $t("profileBasicSave")
        }}</v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import config from "@/config.js";
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { changePassword } from "../../services/authService";
import ConfirmDlg from "../Platform/ConfirmDlg.vue";
import MessageBox from "./MessageBox.vue";
export default {
  components: {
    ConfirmDlg,
    MessageBox
  },
  data: () => {
    return {
      oldPassword: "",
      password: "",
      rePassword: "",
      show0: false,
      show1: false,
      show2: false,
      messageBox: {
        state: false,
        content: "",
        cssClass: "error",
        destination: ""
      }
    };
  },
  mixins: [validationMixin],
  validations: {
    password: {
      required,
      minLength: minLength(8),
      containsUppercase: function (value) {
        return /[A-Z]/.test(value);
      },
      containsLowercase: function (value) {
        return /[a-z]/.test(value);
      },
      containsNumber: function (value) {
        return /[0-9]/.test(value);
      }
    },
    rePassword: {
      required: required,
      sameAs: sameAs("password")
    }
  },
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t("errorMsg.signup.passwordEmpty"),
        min: (v) => v.length >= 8 || this.$t("errorMsg.signup.passwordFormat"),
        containsUppercase: (v) => /[A-Z]/.test(v) || this.$t("errorMsg.signup.passwordFormat"),
        containsLowercase: (v) => /[a-z]/.test(v) || this.$t("errorMsg.signup.passwordFormat"),
        containsNumber: (v) => /[0-9]/.test(v) || this.$t("errorMsg.signup.passwordFormat")
      };
    },
    pwMatch() {
      return this.password === this.rePassword || this.$t("errorMsg.signup.passwordNotMatch");
    },
    pwErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push(this.$t("errorMsg.signup.passwordEmpty"));
      !this.$v.password.containsUppercase && errors.push(this.$t("errorMsg.signup.passwordFormat"));
      !this.$v.password.containsLowercase && errors.push(this.$t("errorMsg.signup.passwordFormat"));
      !this.$v.password.containsNumber && errors.push(this.$t("errorMsg.signup.passwordFormat"));

      return errors;
    }
  },
  methods: {
    async passwordDialog() {
      // const index = this.works.indexOf(item);
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (await this.$refs.confirm.open("", this.$t("dialogMsg.basic.changePassword"))) {
          this.passwordConfirmChanges();
        }
      }
    },
    async passwordConfirmChanges() {
      const token = this.$session.get("token");
      const payload = {
        password: this.oldPassword,
        newPassword: this.password
      };
      const result = await changePassword(this.$axios, config, payload, token);

      if (result.status === 200) {
        this.$store.commit("showProgressLine", false);
        this.$emit("close");
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.state = true;
        this.messageBox.cssClass = "message";
        this.messageBox.destination = "";
        this.$store.commit("showProgressLine", false);
      }
    }
  }
};
</script>
