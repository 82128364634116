<template>
  <div>
    <message-box
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <div class="py-1">
      <div class="d-flex align-center" style="display: flex; flex-wrap: wrap; justify-content: center">
        <div id="verify" class="flex-grow-1 mb-5">
          <VuePhoneNumberInput
            v-model="telClean"
            :preferred-countries="['CN', 'HK']"
            class="phoneInput"
            default-country-code="HK"
            required
            @update="updatePhone"
            valid-color="green"
            size="lg"
            clearable
            :translations="phoneInputTranslations"
            data-cy="phoneInput"
          />
        </div>
        <v-btn
          depressed
          rounded
          outlined
          color="primary"
          small
          width="100px"
          :class="$screen.width > 600 ? 'ml-5 mb-5' : 'mb-5'"
          @click="sendPhoneCode"
          :disabled="!phoneValid"
          data-cy="phoneVerify"
          >{{ $t("profileBasicVerify") }}</v-btn
        >
      </div>
    </div>
    <div v-if="phoneCodeSent">
      <div>
        <v-text-field
          outlined
          v-model="phoneCode"
          messages=""
          prefix="M -"
          dense
          :label="$t('profileBasicMobileCode')"
          hide-details
          data-cy="phoneCode"
        ></v-text-field>
        <p v-if="countDown > 0">
          {{ $t("signWait", { countDown: this.countDown }) }}
        </p>
        <p v-else>
          <a @click="sendPhoneCode" data-cy="phoneResend">
            {{ this.$t("signResend") }}
          </a>
        </p>
      </div>
    </div>
    <div class="buttonLayout">
      <v-btn depressed rounded large :width="$screen.width > 600 ? '160px' : '100px'" @click="$emit('close')">{{
        $t("profileBasicCancel")
      }}</v-btn>

      <v-btn
        depressed
        rounded
        color="primary"
        :width="$screen.width > 600 ? '160px' : '100px'"
        large
        :disabled="!phoneCodeSent"
        @click="phoneConfirmChanges"
        data-cy="phoneSave"
        >{{ $t("profileBasicSave") }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import config from "../../config";
import { checkPhoneVerificationCode, sendVerifCodeToPhone, updateUserProfile } from "../../services/authService";
import MessageBox from "./MessageBox.vue";
export default {
  components: {
    VuePhoneNumberInput,
    MessageBox
  },
  data: () => ({
    tel: "",
    telClean: "",
    phoneCodeSent: false,
    phoneValid: false,
    phoneCode: "",
    countDown: 0,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    }
  }),
  computed: {
    phoneInputTranslations() {
      return {
        countrySelectorLabel: this.$t("signMobileCountry"),
        phoneNumberLabel: this.$t("signMobileBox")
      };
    }
  },
  methods: {
    async sendPhoneCode() {
      if (this.countDown === 0) {
        let language;
        switch (this.$i18n.locale) {
          case "en":
            language = 0;
            break;
          case "zhHant":
            language = 1;
            break;
          case "zhHans":
            language = 2;
            break;
          default:
            language = 0;
        }
        const params = {
          userid: this.$store.state.userProfile.userid,
          language: language
        };
        const result = await sendVerifCodeToPhone(this.$axios, config, this.tel, params);
        if (result.status === 200) {
          this.countDown = 60;
          this.countDownTimer();
          this.phoneCodeSent = true;
          const payload = {
            phoneVerifyCount: this.$store.state.signupForm.phoneVerifyCount + 1
          };
          this.$store.commit("editSignupForm", payload);
        } else {
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
        }
      } else {
        if (!this.messageBox.state) {
          this.messageBox.content = this.$t("signWait", {
            countDown: this.countDown
          });
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
        }
      }
    },
    async checkVerificationCodePhone() {
      const result = await checkPhoneVerificationCode(this.$axios, config, this.tel, this.phoneCode);

      if (result.status === 200) {
        if (result.data.isMatch) {
          return "match";
        } else {
          this.messageBox.content = this.$t("errorMsg.signup.phoneVerifyWrong");
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
          return "notMatch";
        }
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
        return "notMatch";
      }
    },
    async phoneConfirmChanges() {
      const verificationResult = await this.checkVerificationCodePhone();

      if (verificationResult === "match") {
        const token = this.$session.get("token");
        const payload = {
          phone: this.tel,
          phoneVerificationCode: this.phoneCode
        };

        const result = await updateUserProfile(this.$axios, config, payload, token);

        if (result.status === 200) {
          this.$store.commit("refreshUserProfile", result.data);
          this.$emit("close");
          this.countDown = 0;
          this.phoneCodeSent = false;
        } else {
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
        }
      }
    },
    updatePhone(e) {
      this.phoneValid = e.isValid;
      this.tel = e.formatInternational;
      this.phoneCodeSent = false;
      this.phoneCode = "";
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    }
  }
};
</script>

<style></style>
