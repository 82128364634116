<template>
  <div>
    <v-row class="profileTitle">
      <v-col></v-col>
    </v-row>
    <div v-if="!loading">
      <v-row>
        <v-col cols="12" md="12"
          ><h1 v-if="$screen.width >= 900">{{ $t("profileNav.Social") }}</h1></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          {{ $t("profileSocialFullName") }}
          <v-text-field
            v-model="socialProfile.name"
            dense
            outlined
            hide-details=""
            :placeholder="$t('profileSocialFullName')"
            clearable
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          {{ $t("profileSocialLocation") }}
          <v-select
            dense
            outlined
            hide-details=""
            :placeholder="$t('profileSocialFullName')"
            :items="countryList"
            :item-text="$i18n.locale"
            :item-value="'alpha-3'"
            v-model="socialProfile.location"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12">
          {{ $t("profileSocialAboutMe") }}
          <v-textarea
            v-model="socialProfile.bio"
            clearable
            dense
            outlined
            hide-details=""
            :placeholder="$t('profileSocialAboutMe')"
          ></v-textarea>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            prepend-inner-icon="mdi-facebook"
            dense
            outlined
            hide-details=""
            :placeholder="$t('profileSocialFacebook')"
            v-model="socialProfile.facebook"
            clearable
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            prepend-inner-icon="mdi-twitter"
            dense
            outlined
            hide-details=""
            :placeholder="$t('profileSocialTwitter')"
            clearable
            v-model="socialProfile.twitter"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            prepend-inner-icon="mdi-instagram"
            dense
            outlined
            hide-details=""
            :placeholder="$t('profileSocialInstagram')"
            clearable
            v-model="socialProfile.instagram"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            prepend-inner-icon="mdi-earth"
            dense
            outlined
            hide-details=""
            :placeholder="$t('profileSocialWebsite')"
            clearable
            v-model="socialProfile.website"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-alert class="mt-5" v-model="success" dismissible type="success" text dense>{{
        $t("dialogMsg.social.successful")
      }}</v-alert>
      <div class="buttonLayout">
        <v-btn
          rounded
          color="primary"
          outlined
          large=""
          depressed
          :width="$screen.width > 900 ? '150px' : 'auto'"
          data-cy="back"
          :ripple="false"
          :to="`/platform/user/${this.userid}`"
          ><span style="font-weight: 600">{{ $t("profileSocialView") }} </span></v-btn
        >
        <v-btn
          rounded
          color="primary"
          large=""
          depressed
          :width="$screen.width > 900 ? '150px' : 'auto'"
          @click="submit"
          :ripple="false"
          data-cy="submit"
          :loading="uploadLoading"
          ><span style="font-weight: 600">{{ $t("profilePersonConfirm") }} </span></v-btn
        >
      </div>
    </div>
    <div class="d-flex align-center justify-center" style="min-height: 60vh" v-else>
      <v-progress-circular indeterminate size="60" color="primary" v-if="loading"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import countryList from "@/data/countryList";
import config from "../../config";
import { updatePublicRoleInfo } from "../../services/authService";
// import { updatePublicRoleInfo } from "../../services/authService";

export default {
  data: () => ({
    socialProfile: {
      name: "",
      location: "",
      bio: "",
      facebook: "",
      twitter: "",
      instagram: "",
      website: ""
    },
    uploadLoading: false,
    loading: false,
    success: false
  }),
  computed: {
    countryList() {
      return countryList;
    },
    userid() {
      return this.$store.state.userProfile.userid;
    }
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("loadUserProfile", {
      axios: this.$axios,
      config: config,
      token: this.$session.get("token")
    });
    if (Object.hasOwnProperty.call(this.$store.state.userProfile.publicRoleInfo, "profile")) {
      this.socialProfile = this.$store.state.userProfile.publicRoleInfo.profile;
    }

    this.loading = false;
  },
  methods: {
    async submit() {
      this.uploadLoading = true;
      let publicRoleInfo = this.$store.state.userProfile.publicRoleInfo;
      publicRoleInfo.profile = this.socialProfile;
      const token = this.$session.get("token");
      const updatePublicRoleInfoResult = await updatePublicRoleInfo(this.$axios, config, publicRoleInfo, token);
      if (updatePublicRoleInfoResult.status === 200) {
        this.success = true;
        this.updatingInfo = false;
      } else {
        this.messageBox = {
          content: `Cannot update user info. ${updatePublicRoleInfoResult.data.msg}`,
          state: true,
          cssClass: "error",
          destination: ""
        };
        return;
      }
      this.uploadLoading = false;
    }
  }
};
</script>
