<template>
  <v-row>
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <confirm-dlg ref="confirm" />
    <v-col class="d-flex justify-center flex-column">
      <p class="ma-0">{{ $t("profileBasicLanguage") }}</p>
    </v-col>
    <v-col cols="12" md="9">
      <div class="editField">
        <div id="verify">
          <v-select
            :items="languageList"
            v-model="language"
            item-text="title"
            item-value="value"
            dense
            hide-details=""
            data-cy="language"
            outlined
          ></v-select>
        </div>
      </div>
    </v-col>
    <v-col>
      <div class="buttonLayout">
        <v-btn depressed rounded width="160px" large @click="$emit('close')" data-cy="cancel">{{
          $t("dialogButton.cancel")
        }}</v-btn>
        <v-btn
          depressed
          rounded
          color="primary"
          width="160px"
          large
          @click="languageConfirmChange"
          data-cy="languageSave"
          >{{ $t("profileBasicSave") }}</v-btn
        >
      </div>
    </v-col>
  </v-row>
</template>

<script>
import config from "@/config.js";
import { updateUserProfile } from "../../services/authService";
import ConfirmDlg from "../Platform/ConfirmDlg.vue";
import MessageBox from "./MessageBox.vue";
export default {
  components: {
    ConfirmDlg,
    MessageBox
  },
  created() {
    if (Object.hasOwnProperty.call(this.$store.state.userProfile, "language")) {
      this.language = this.$store.state.userProfile.language;
    }
  },
  data: () => {
    return {
      languageList: [
        { title: "English", countryCode: "us", locale: "en", value: 0 },
        { title: "繁體中文", countryCode: "hk", locale: "zhHant", value: 1 },
        { title: "简体中文", countryCode: "cn", locale: "zhHans", value: 2 }
      ],
      language: null,
      messageBox: {
        state: false,
        content: "",
        cssClass: "error",
        destination: ""
      }
    };
  },
  methods: {
    async languageConfirmChange() {
      const token = this.$session.get("token");
      const payload = {
        language: this.language
      };

      const result = await updateUserProfile(this.$axios, config, payload, token);
      if (result.status === 200) {
        this.$emit("close");
        this.$i18n.locale = this.languageList[result.data.language].locale;
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.state = true;
        this.messageBox.cssClass = "error";
        this.messageBox.destination = "";
      }
    }
  }
};
</script>
