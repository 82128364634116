<template>
  <div class="py-1">
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <div class="d-flex align-center" style="display: flex; flex-wrap: wrap; justify-content: center">
      <div id="verify" style="min-width: 200px" class="flex-grow-1 mb-5">
        <v-text-field
          outlined
          v-model="emailField"
          messages=""
          dense
          :label="$t('profileBasicEmailNew')"
          @blur="$v.emailField.$touch()"
          :error-messages="emailErrors"
          hide-details="auto"
          data-cy="emailField"
        ></v-text-field>
      </div>

      <v-btn
        depressed
        rounded
        outlined
        color="primary"
        small
        class="ml-5 mb-5"
        width="100px"
        @click="sendEmailCode"
        :disabled="$v.emailField.$invalid"
        data-cy="emailVerify"
        >{{ $t("profileBasicVerify") }}</v-btn
      >
    </div>

    <div v-if="emailCodeSent">
      <div>
        <v-text-field
          outlined
          v-model="emailCode"
          messages=""
          prefix="E -"
          dense
          :label="$t('profileBasicEmailCode')"
          hide-details
          data-cy="emailCode"
        ></v-text-field>
        <p v-if="countDown > 0">
          {{ this.$t("signWait", { countDown: this.countDown }) }}
        </p>
        <p v-else>
          <a @click="sendPhoneCode">
            {{ this.$t("signResend") }}
          </a>
        </p>
      </div>
    </div>
    <div class="buttonLayout">
      <v-btn depressed rounded :width="$screen.width > 600 ? '160px' : '100px'" large @click="$emit('close')">{{
        $t("dialogButton.cancel")
      }}</v-btn>

      <v-btn
        depressed
        rounded
        color="primary"
        :width="$screen.width > 600 ? '160px' : '100px'"
        large
        :disabled="!emailCodeSent"
        @click="emailConfirmChanges"
        data-cy="emailConfirmChanges"
        >{{ $t("profileBasicSave") }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import config from "../../config";
import { checkEmailVerificationCode, sendVerifCodeToEmail, updateUserProfile } from "../../services/authService";
import { required, email } from "vuelidate/lib/validators";
import MessageBox from "@/components/Common/MessageBox";
export default {
  mixins: [validationMixin],
  components: {
    MessageBox
  },
  validations: {
    emailField: { required, email }
  },
  data: () => ({
    countDown: 0,
    emailField: "",
    emailCodeSent: false,
    emailCode: "",
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    }
  }),
  methods: {
    async sendEmailCode() {
      if (this.countDown === 0) {
        let language;
        switch (this.$i18n.locale) {
          case "en":
            language = 0;
            break;
          case "zhHant":
            language = 1;
            break;
          case "zhHans":
            language = 2;
            break;
          default:
            language = 0;
        }
        const params = {
          userid: this.$store.state.userProfile.userid,
          language: language
        };
        const result = await sendVerifCodeToEmail(this.$axios, config, this.emailField, params);

        if (result.status === 200) {
          this.messageBox.content = this.$t("signEmailVerifyText", {
            email: this.emailField
          });
          this.messageBox.cssClass = "primary";
          this.messageBox.state = true;
          this.messageBox.destination = "";
          this.countDown = 60;
          this.countDownTimer();
          this.emailCodeSent = true;
          const payload = {
            emailVerifyCount: this.$store.state.signupForm.emailVerifyCount + 1
          };
          this.$store.commit("editSignupForm", payload);
        } else {
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.state = true;
          this.messageBox.destination = "";
          this.$store.commit("showProgressLine", false);
        }
      } else {
        if (!this.messageBox.state) {
          this.messageBox.content = this.$t("signWait", {
            countDown: this.countDown
          });
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
        }
      }
    },
    async emailConfirmChanges() {
      const verificationResult = await this.checkVerificationCodeEmail();

      if (verificationResult === "match") {
        const token = this.$session.get("token");
        const payload = {
          email: this.emailField,
          emailVerificationCode: this.emailCode
        };
        const result = await updateUserProfile(this.$axios, config, payload, token);

        if (result.status === 200) {
          this.$store.commit("refreshUserProfile", result.data);
          this.$emit("close");
          this.countDown = 0;
          this.emailCodeSent = false;
        } else {
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.state = true;
          this.messageBox.destination = "";
          this.$store.commit("showProgressLine", false);
        }
      }
    },
    async checkVerificationCodeEmail() {
      const result = await checkEmailVerificationCode(this.$axios, config, this.emailField, this.emailCode);

      if (result.status === 200) {
        if (result.data.isMatch) {
          return "match";
        } else {
          this.messageBox.content = this.$t("errorMsg.signup.emailVerifyWrong");
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
          return "notMatch";
        }
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
        return "notMatch";
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    }
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.emailField.$dirty) return errors;
      !this.$v.emailField.email && errors.push(this.$t("errorMsg.signup.emailWrong"));
      !this.$v.emailField.required && errors.push(this.$t("errorMsg.signup.emailEmpty"));
      return errors;
    }
  }
};
</script>

<style></style>
