<template>
  <div key="basic">
    <confirm-dlg ref="confirm" />
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <div class="profileTitle">
      <h1 v-if="$screen.width >= 900" style="margin-bottom: 24px">{{ $t("profileBasicHeading") }}</h1>
    </div>
    <div class="mb-3" v-if="notEditing">{{ $t("profileBasicIcon") }}</div>
    <div class="profileImage" v-if="notEditing">
      <avatar-from-file-descriptor
        :size="100"
        :fileDescriptor="this.$store.getters.profileImage"
        :userid="this.$store.state.userProfile.userid"
        :key="this.$store.getters.profileImage.fileName"
      ></avatar-from-file-descriptor>

      <div id="controls">
        <profile-image-uploader @input="uploadProfilePicture">
          <div slot="activator">
            <div>
              <v-btn
                data-cy="profileBasicChange"
                class="ma-1"
                width="100px"
                depressed
                small
                outlined
                color="primary"
                rounded
              >
                {{ $t("profileBasicChange") }}
              </v-btn>
            </div>
          </div>
        </profile-image-uploader>
        <v-btn
          class="ma-1"
          width="100px"
          depressed
          small
          outlined
          rounded
          @click="removeProfilePicture"
          v-if="profileImage"
          data-cy="profileBasicIconRemove"
        >
          {{ this.$t("profileBasicIconRemove") }}
        </v-btn>
      </div>
    </div>
    <v-container class="profileItem">
      <v-row v-if="notEditing">
        <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profileBasicUser") }}</v-col>
        <v-col cols="9" md="7" class="py-0 d-flex align-center" style="font-weight: bold">
          {{ this.userid }}
        </v-col>
        <v-col cols="3" md="2"></v-col>
      </v-row>

      <v-row v-if="notEditing">
        <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profileBasicMember") }}:</v-col>
        <v-col cols="9" md="7" class="py-0 d-flex align-center" style="font-weight: bold">
          <div v-bind:style="{ color: isPremium ? '#63a3c3' : '#000000' }">
            {{ membership }}
          </div>
        </v-col>
        <v-col cols="3" md="2">
          <div class="changeButtonLayout">
            <v-btn
              depressed
              rounded
              outlined
              small
              color="primary"
              to="/platform/premium"
              data-cy="profileBasicMemberUpgrade"
              >{{ isPremium ? $t("profileBasicMemberUpgradeAfter") : $t("profileBasicMemberUpgrade") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <v-row v-if="notEditing || referralEditing">
        <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profileBasicReferral") }}:</v-col>
        <v-col cols="9" md="7" class="py-0 d-flex align-center" style="font-weight: bold">
          <div v-if="referralDiscount">
            <div
              v-html="
                $t('profileBasicReferralCode', {
                  referralCode: referralCode,
                  discount: Math.round((1 - parseFloat(referralDiscount)) * 100),
                  expiryDate: new Date(referralExpiryDate).toISOString().substr(0, 10)
                })
              "
            ></div>
          </div>
          <div v-else>{{ $t("profileBasicReferralCodeNone") }}</div>
        </v-col>
        <v-col cols="3" md="2">
          <div class="changeButtonLayout">
            <v-btn
              depressed
              small
              outlined
              rounded
              color="primary"
              @click="referralEditing = !referralEditing"
              v-if="!referralEditing"
              :disabled="!!referralCode || isPremium"
              data-cy="referralEditing"
              >{{ $t("profileBasicChange") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <profile-basic-password v-if="passwordEditing" @close="passwordEditing = !passwordEditing" />
      <v-row v-if="referralEditing">
        <v-col cols="12" md="3" class="py-0 d-flex align-center"></v-col>
        <v-col cols="9">
          <div class="editField">
            <div id="verify">
              <v-text-field
                outlined
                v-model="referralField"
                messages=""
                dense
                :label="$t('profileBasicReferral')"
                data-cy="profileBasicReferral"
                hide-details
              ></v-text-field>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="notEditing || emailEditing">
        <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profileBasicEmail") }}:</v-col>
        <v-col cols="9" md="7" class="py-0 d-flex align-center" style="font-weight: bold">
          <div>
            {{ this.email }}
          </div>
        </v-col>
        <v-col cols="3" md="2">
          <div class="changeButtonLayout">
            <v-btn
              depressed
              small
              outlined
              rounded
              color="primary"
              @click="editEmail"
              v-if="!emailEditing"
              :disabled="this.phoneEditing"
              data-cy="phoneEditing"
              >{{ $t("profileBasicChange") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <v-row v-if="emailEditing">
        <v-col cols="12" md="3" class="py-0 d-flex align-center"></v-col>
        <v-col cols="12" md="9"> <profile-basic-email @close="emailEditing = false" /> </v-col
      ></v-row>

      <v-row v-if="notEditing || phoneEditing">
        <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profileBasicMobile") }}:</v-col>
        <v-col cols="9" md="7" class="py-0 d-flex align-center" style="font-weight: bold">
          <div>+{{ this.phone }}</div>
        </v-col>
        <v-col cols="3" md="2">
          <div class="changeButtonLayout" v-if="!this.phoneEditing">
            <v-btn
              depressed
              small
              outlined
              rounded
              color="primary"
              @click="editPhone"
              :disabled="this.emailEditing"
              data-cy="emailEditing"
              >{{ $t("profileBasicChange") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <v-row v-if="phoneEditing">
        <v-col cols="12" md="3" class="py-0 d-flex align-center"></v-col>
        <v-col cols="12" md="9"> <profile-basic-phone @close="phoneEditing = false" /> </v-col
      ></v-row>

      <v-row v-if="notEditing">
        <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("signPasswordBox") }}:</v-col>
        <v-col cols="9" md="7" class="py-0 d-flex align-center" style="font-weight: bold">********</v-col>
        <v-col cols="3" md="2">
          <div class="changeButtonLayout">
            <v-btn depressed rounded outlined small color="primary" @click="editPassword" data-cy="editPassword">{{
              $t("profileBasicChange")
            }}</v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="notEditing">
        <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profileBasicLanguage") }}</v-col>
        <v-col cols="9" md="7" class="py-0 d-flex align-center" style="font-weight: bold" v-if="!languageEditing">{{
          languageLabel
        }}</v-col>

        <v-col cols="3" md="2">
          <div class="changeButtonLayout">
            <v-btn
              depressed
              small
              outlined
              rounded
              color="primary"
              @click="languageEditing = true"
              v-if="!languageEditing"
              data-cy="languageEditing"
              >{{ $t("profileBasicChange") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <profile-basic-language v-if="languageEditing" @close="closeLanguageEditing" />
      <v-row v-if="notEditing">
        <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profileBasicMarketing") }}:</v-col>
        <v-col cols="9" md="7" class="py-0 d-flex align-center" style="font-weight: bold">{{
          $t("signMarketing")
        }}</v-col>
        <v-col cols="2" style="display: flex; justify-content: flex-end">
          <div style="width: 100px; display: flex; align-items: center; justify-content: center">
            <switch-control v-model="marketingConsent" color="#63a3c3" @toggle="marketingConsentUpdate" />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="notEditing">
        <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profileBasicStorageQuota") }}:</v-col>
        <v-col cols="9" md="7" class="py-0 d-flex align-center" style="font-weight: bold">{{ storageQuota }} KB</v-col>
      </v-row>
    </v-container>
    <div>
      <div v-if="this.referralEditing" class="changeButtonLayout">
        <v-btn
          depressed
          rounded
          width="160px"
          large
          @click="referralEditing = !referralEditing"
          data-cy="cancelReferralEditing"
          >{{ $t("dialogButton.cancel") }}</v-btn
        >
        <v-btn
          depressed
          rounded
          color="primary"
          width="160px"
          large
          @click="referralCodeDialog"
          :disabled="referralField === ''"
          data-cy="saveReferralEditing"
          >{{ $t("profileBasicSave") }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { updatePublicRoleInfo } from "@/services/authService";
import { uploadCoverPhotoToBFS } from "@/services/fileService";
import config from "@/config.js";
import MessageBox from "@/components/Common/MessageBox";

import ProfileImageUploader from "@/components/Platform/ProfileImageUploader.vue";
import AvatarFromFileDescriptor from "@/components/Common/AvatarFromFileDescriptor";
import PlatformLayout from "@/layouts/Platform";
import { getReferralRecord, updateReferralCode, updateRoleInfo } from "../../services/authService";
import ConfirmDlg from "./ConfirmDlg.vue";
import ProfileBasicPassword from "../Common/ProfileBasicPassword.vue";
import ProfileBasicPhone from "../Common/ProfileBasicPhone.vue";
import ProfileBasicEmail from "../Common/ProfileBasicEmail.vue";
import ProfileBasicLanguage from "../Common/ProfileBasicLanguage.vue";
import SwitchControl from "../Common/SwitchControl.vue";

export default {
  name: "ProfileBasic",

  data: () => ({
    premierExpiryDate: null,

    emailEditing: false,

    phoneEditing: false,

    countDown: 0,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },

    fileDescriptor: null,
    profileImage: "",
    profileImageLoading: false,
    languageEditing: false,
    language: 0,
    languageList: [
      { title: "English", countryCode: "us", locale: "en", value: 0 },
      { title: "繁體中文", countryCode: "hk", locale: "zhHant", value: 1 },
      { title: "简体中文", countryCode: "cn", locale: "zhHans", value: 2 }
    ],
    referralEditing: false,
    referralField: "",
    referralDiscount: null,
    referralExpiryDate: null,
    passwordEditing: false,
    passwordValid: false,
    marketingConsent: null
  }),
  components: {
    MessageBox,
    ProfileImageUploader,
    AvatarFromFileDescriptor,
    ConfirmDlg,
    ProfileBasicPassword,
    ProfileBasicPhone,
    ProfileBasicEmail,
    ProfileBasicLanguage,
    SwitchControl
  },

  created() {
    ConfirmDlg;
    this.$emit(`update:layout`, PlatformLayout);
    if (Object.hasOwnProperty.call(this.$store.state.userProfile, "language")) {
      this.language = this.$store.state.userProfile.language;
    }
    this.getUserProfile();
  },
  methods: {
    editPhone() {
      if (!this.emailEditing) {
        this.phoneEditing = !this.phoneEditing;
      }
    },
    editEmail() {
      if (!this.phoneEditing) {
        this.emailEditing = !this.emailEditing;
      }
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    enableSubmitPhone(code) {
      this.phoneCode = code;
    },
    enableSubmitEmail(code) {
      this.emailCode = code;
    },

    editPassword() {
      this.passwordEditing = !this.passwordEditing;
      this.oldPassword = "";
      this.password = "";
      this.rePassword = "";
    },
    async uploadProfilePicture(file) {
      const token = this.$session.get("token");
      let imageFormData = file.formData;
      this.$store.commit("setAvatar", null);
      const result = await uploadCoverPhotoToBFS(this.$axios, config, imageFormData, token);
      if (result.status === 200) {
        this.fileDescriptor = result.data.fileDescriptor;
        this.profileImageConfirmChanges();
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
      }
    },
    async getUserProfile() {
      await this.$store.dispatch("loadUserProfile", {
        axios: this.$axios,
        config: config,
        token: this.$session.get("token")
      });
      if (this.referralCode) {
        const result = await getReferralRecord(this.$axios, config, this.referralCode);
        if (result.status === 200) {
          this.referralDiscount = result.data.discount;
          this.referralExpiryDate = result.data.expiryDate;
        }
      }
      try {
        this.profileImage = this.$store.state.userProfile.publicRoleInfo.iconPhoto.fileDescriptor;
      } catch (error) {
        (error) => error;
      }
    },
    async profileImageConfirmChanges() {
      let roleInfo = this.$store.state.userProfile.publicRoleInfo;
      const token = this.$session.get("token");
      if (typeof roleInfo === "string" || roleInfo instanceof String) {
        roleInfo = {
          iconPhoto: {
            fileDescriptor: this.fileDescriptor
          }
        };
      } else {
        roleInfo.iconPhoto = {
          fileDescriptor: this.fileDescriptor
        };
      }

      const result = await updatePublicRoleInfo(this.$axios, config, roleInfo, token);
      if (result.status === 200) {
        await this.getUserProfile();
      }
    },
    async removeProfilePicture() {
      const prompt = "Remove the profile picture?";

      if (await this.$refs.confirm.open("", prompt)) {
        this.$store.commit("setAvatar", null);
        const roleInfo = this.$store.state.userProfile.publicRoleInfo;
        const token = this.$session.get("token");
        delete roleInfo.iconPhoto;
        const result = await updatePublicRoleInfo(this.$axios, config, roleInfo, token);
        if (result.status === 200) {
          await this.$store.dispatch("loadUserProfile", {
            axios: this.$axios,
            config: config,
            token: this.$session.get("token")
          });
          this.profileImage = null;
        } else {
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
          this.$store.commit("showProgressLine", false);
        }
      }
    },
    async referralCodeDialog(item) {
      // const index = this.works.indexOf(item);
      this.editedItem = item;
      if (await this.$refs.confirm.open("", this.$t("dialogMsg.basic.referral"))) {
        this.updateReferralCode();
      }
    },
    async updateReferralCode() {
      const token = this.$session.get("token");

      const result = await updateReferralCode(this.$axios, config, token, this.referralField);
      if (result.status === 200) {
        await this.$store.dispatch("loadUserProfile", {
          axios: this.$axios,
          config: config,
          token: this.$session.get("token")
        });
        this.getUserProfile();
        this.referralEditing = false;
      } else if (result.status === 400) {
        this.messageBox.content = this.$t("dialogMsg.basic.referralError");
        this.messageBox.state = true;
        this.messageBox.destination = "";
        this.$store.commit("showProgressLine", false);
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
      }
    },
    closeLanguageEditing() {
      this.languageEditing = false;
      if (Object.hasOwnProperty.call(this.$store.state.userProfile, "language")) {
        this.language = this.$store.state.userProfile.language;
      }
    },
    marketingConsentUpdate(selection) {
      let roleInfo = this.$store.state.userProfile.roleInfo;
      roleInfo.marketingConsent = selection;
      updateRoleInfo(this.$axios, config, roleInfo, this.$session.get("token"));
    }
  },
  computed: {
    profileImageFromState() {
      return this.$store.getters.profileImage;
    },
    membership() {
      if (this.isPremium) {
        const dateStr = new Date(this.$store.state.userProfile.premiumExpiryDate).toISOString().substr(0, 10);
        return this.$t("profileBasicMemberTypePremium", { expiryDate: dateStr });
      } else {
        return this.$t("profileBasicMemberTypeFree");
      }
    },
    isPremium() {
      return this.$store.state.userProfile.isPremium;
    },

    userid() {
      return this.$store.state.userProfile.userid;
    },
    email() {
      return this.$store.state.userProfile.email;
    },
    phone() {
      return this.$store.state.userProfile.phone;
    },
    emailValid() {
      return !this.$v.emailField.$error;
    },
    languageLabel() {
      if (this.$store.state.userProfile.language) {
        return this.languageList[this.$store.state.userProfile.language].title;
      } else {
        return this.languageList[this.language].title;
      }
    },
    useridSubstring() {
      if (this.$store.state.userProfile.userid) {
        return this.$store.state.userProfile.userid.toString().substring(0, 2).toUpperCase();
      } else {
        return null;
      }
    },
    storageQuota() {
      return this.$store.state.userProfile.storageQuota;
    },
    referralCode() {
      return this.$store.state.userProfile.referralCode;
    },
    profileNavArray() {
      const content = this.$t("profileNav");
      const result = content.replace(/['"]+/g, "").replace(/['“]+/g, "").replace(/['”]+/g, "").split(",");
      return result;
    },

    notEditing() {
      return (
        !this.emailEditing &&
        !this.phoneEditing &&
        !this.referralEditing &&
        !this.languageEditing &&
        !this.passwordEditing
      );
    }
  }
};
</script>
