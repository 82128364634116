<template>
  <div>
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <!-- slot for parent component to activate the file changer -->
    <div @click="launchFilePicker()" style="display: inline-flex">
      <slot name="activator"></slot>
    </div>
    <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
    <input
      type="file"
      ref="file"
      :name="uploadFieldName"
      @change="onFileChange($event.target.name, $event.target.files)"
      style="display: none"
    />
  </div>
</template>

<script>
import MessageBox from "../Common/MessageBox.vue";

export default {
  name: "image-input",
  components: {
    MessageBox
  },
  data: () => ({
    errorDialog: null,
    errorText: "",
    uploadFieldName: "file",
    maxSize: 2e6,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    }
  }),
  props: {
    // Use "value" to enable using v-model
    value: Object
  },
  methods: {
    launchFilePicker() {
      this.$refs.file.click();
    },
    onFileChange(fieldName, file) {
      const { maxSize } = this;
      let imageFile = file[0];
      if (file.length > 0) {
        let size = imageFile.size;
        if (!imageFile.type.match("image.*")) {
          // check whether the upload is an image
          this.messageBox.content = `Please upload an image file.`;
          this.messageBox.cssClass = "message";
          this.messageBox.state = true;
          this.messageBox.destination = "";
        } else if (size > maxSize) {
          // check whether the size is greater than the size limit
          this.messageBox.content = `Please upload an image less than 2MB.`;
          this.messageBox.cssClass = "message";
          this.messageBox.state = true;
          this.messageBox.destination = "";
        } else {
          // Append file into FormData and turn file into image URL
          let formData = new FormData();
          let imageURL = URL.createObjectURL(imageFile);
          formData.append(fieldName, imageFile);
          // Emit the FormData and image URL to the parent component
          this.$emit("input", { formData, imageURL });
        }
      }
    }
  }
};
</script>
