<template>
  <div>
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
      :emitEventConfirm="messageBox.confirmEvent"
      @confirmUpdateSuccess="confirmUpdateSuccess"
    />
    <confirm-dlg ref="confirm" />
    <div class="profileTitle">
      <h1 v-if="$screen.width >= 900">{{ $t("profilePersonHeading") }}</h1>
      <h5 style="margin-bottom: 24px" v-if="!this.$store.state.userProfile.roleInfo.idUploadDate">
        {{ $t("profilePersonSubheading") }}
      </h5>
      <h5 style="margin-bottom: 24px" v-if="$store.state.userProfile.roleInfo.idUploadDate">
        {{
          $t("dialogMsg.personal.lastUpdated", {
            date: new Date($store.state.userProfile.roleInfo.idUploadDate).toISOString().substr(0, 10)
          })
        }}
      </h5>
    </div>
    <div v-if="!updatingInfo" class="profileItem">
      <v-dialog v-model="passwordDialog" width="500" persistent>
        <v-card style="padding: 30px">
          <h5 class="sb">{{ $t("dialogMsg.profile.password") }}</h5>
          <v-text-field
            v-model="password"
            :label="$t('loginPassword')"
            outlined
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            :error-messages="passwordMessage"
            class="my-5"
            hide-details="auto"
            @keyup.enter="enterChangeDetails"
          ></v-text-field>

          <div style="width: 100%; display: flex; justify-content: space-between">
            <v-btn
              @click="enterChangeDetails"
              depressed
              color="primary"
              rounded
              large
              min-width="100px"
              :loading="passwordLoading"
              :disabled="passwordLoading"
              >{{ $t("profilePersonConfirm") }}</v-btn
            >
            <v-btn
              @click="closePasswordDialog"
              depressed
              color="secondary"
              rounded
              large
              min-width="100px"
              class="ml-2"
              >{{ $t("profilePersonBack") }}</v-btn
            >
          </div>
        </v-card>
      </v-dialog>
      <div v-if="$store.state.userProfile.roleInfo.idUploadDate">
        <v-row>
          <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profilePersonIDType").substr(2) }}</v-col>
          <v-col cols="9" md="9" class="py-0 d-flex align-center" style="font-weight: bold">
            {{
              $store.state.userProfile.roleInfo.idType === 4
                ? idTypeItems[0].label
                : idTypeItems[$store.state.userProfile.roleInfo.idType].label
            }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" class="py-0 d-flex align-center">{{
            isBusiness ? $t("profilePersonNameBusiness").substr(2) : $t("profilePersonName").substr(2)
          }}</v-col>
          <v-col cols="9" md="9" class="py-0 d-flex align-center" style="font-weight: bold">
            {{ $store.state.userProfile.roleInfo.idName }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" class="py-0 d-flex align-center">{{
            isBusiness ? $t("profilePersonBirthBusiness").substr(2) : $t("profilePersonBirth").substr(2)
          }}</v-col>
          <v-col cols="9" md="9" class="py-0 d-flex align-center" style="font-weight: bold">
            {{ $store.state.userProfile.roleInfo.birthday }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" class="py-0 d-flex align-center">{{
            isBusiness ? $t("profilePersonNumBusiness").substr(2) : $t("profilePersonNum").substr(2)
          }}</v-col>
          <v-col cols="9" md="9" class="py-0 d-flex align-center" style="font-weight: bold">
            {{ asterisk($store.state.userProfile.roleInfo.idNumber, "", 3, null) }}
          </v-col>
        </v-row>
        <v-row v-if="$store.state.userProfile.roleInfo.idType == 2">
          <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profilePersonExpiry").substr(2) }}</v-col>
          <v-col cols="9" md="9" class="py-0 d-flex align-center" style="font-weight: bold">
            {{ $store.state.userProfile.roleInfo.idExpiry }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profilePersonCountry").substr(2) }}</v-col>
          <v-col cols="9" md="9" class="py-0 d-flex align-center" style="font-weight: bold">
            {{ getCountryName($store.state.userProfile.roleInfo.countryOfIssue) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profilePersonAddress").substr(2) }}</v-col>
          <v-col cols="9" md="9" class="py-0 d-flex align-center" style="font-weight: bold">
            {{ asterisk($store.state.userProfile.roleInfo.address, ",", 1, null) }}
          </v-col>
        </v-row>
        <v-row v-if="$store.state.userProfile.roleInfo.bankAccount && !$store.state.userProfile.roleInfo.fpsAccount">
          <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profilePersonBankCode") }}</v-col>
          <v-col cols="9" md="9" class="py-0 d-flex align-center" style="font-weight: bold">
            {{ $store.state.userProfile.roleInfo.bankAccount.bankCode }}
          </v-col> </v-row
        ><v-row v-if="$store.state.userProfile.roleInfo.bankAccount && !$store.state.userProfile.roleInfo.fpsAccount">
          <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profilePersonBankNumber") }}</v-col>
          <v-col cols="9" md="9" class="py-0 d-flex align-center" style="font-weight: bold">
            {{ $store.state.userProfile.roleInfo.bankAccount.accountNumber }}
          </v-col> </v-row
        ><v-row v-if="$store.state.userProfile.roleInfo.bankAccount && !$store.state.userProfile.roleInfo.fpsAccount">
          <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profilePersonBankName") }}</v-col>
          <v-col cols="9" md="9" class="py-0 d-flex align-center" style="font-weight: bold">
            {{ $store.state.userProfile.roleInfo.bankAccount.accountName }}
          </v-col>
        </v-row>
        <v-row v-if="$store.state.userProfile.roleInfo.fpsAccount && $store.state.userProfile.roleInfo.bankAccount">
          <v-col cols="12" md="3" class="py-0 d-flex align-center">{{ $t("profilePersonFpsName") }}</v-col>
          <v-col cols="9" md="9" class="py-0 d-flex align-center" style="font-weight: bold">
            {{ $store.state.userProfile.roleInfo.fpsAccount }}
          </v-col>
        </v-row>
      </div>
      <div class="buttonLayout">
        <v-btn
          rounded
          color="primary"
          large=""
          depressed
          :width="$screen.width > 900 ? '300px' : 'auto'"
          @click="passwordDialog = true"
          :ripple="false"
          ><span style="font-weight: 600">{{ this.$t("dialogButton.updateProfile") }} </span></v-btn
        >
      </div>
    </div>
    <div v-else>
      <v-form ref="form" v-model="valid">
        <v-container class="pa-0">
          <v-row>
            <v-col cols="12" md="6">
              <p>{{ $t("profilePersonUpload") }}</p>
              <div class="newFileUploaderContainer">
                <div class="newFileUploader">
                  <div style="display: flex; align-items: center">
                    <img v-if="profileData.idImage" src="@/assets/zeplin/correct-1.svg" class="correct-1 mr-2" />
                    <p
                      v-if="!loading"
                      class="ma-0 mr-2"
                      style="overflow: hidden; line-height: normal; white-space: nowrap; text-overflow: ellipsis"
                    >
                      {{ profileData.idImage ? profileData.idImage.name : $t("createFileUploaderHint") }}
                    </p>
                    <v-progress-circular
                      color="primary"
                      v-if="loading"
                      indeterminate
                      size="15"
                      width="2"
                    ></v-progress-circular>
                  </div>

                  <div class="button-wrap">
                    <label class="button" for="upload">{{ $t("createFileUploaderLabel") }}</label>
                    <input id="upload" type="file" @change="processFile($event)" data-cy="idUpload" />
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <p>{{ $t("profilePersonIDType") }}</p>
              <v-select
                outlined
                dense
                hide-details="auto"
                v-model="profileData.idType"
                :items="idTypeItems"
                item-text="label"
                item-value="index"
                @change="checkIdImage"
                :readonly="!profileData.idImage"
                :filled="!profileData.idImage"
                data-cy="idType"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="idImageChecked">
            <v-col cols="12" md="6">
              <p v-if="profileData.idType !== 3">{{ $t("profilePersonName") }}</p>
              <p v-else>{{ $t("profilePersonNameBusiness") }}</p>
              <v-text-field
                outlined
                dense
                v-model="profileData.idName"
                hide-details="auto"
                :readonly="!idImageChecked"
                :filled="!idImageChecked"
                :rules="requiredFieldRules"
                :loading="loading"
                data-cy="idName"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6"> </v-col>
            <v-col cols="12" md="6">
              <p v-if="profileData.idType !== 3">{{ $t("profilePersonBirth") }}</p>
              <p v-else>{{ $t("profilePersonBirthBusiness") }}</p>
              <v-menu
                ref="dateOfBirthMenu"
                v-model="dateOfBirth.menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                :readonly="!idImageChecked"
                :disabled="!idImageChecked"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="profileData.dateOfBirth"
                    :rules="dateOfBirthRules"
                    hide-details="auto"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :readonly="!idImageChecked"
                    :filled="!idImageChecked"
                    :loading="loading"
                    data-cy="dateOfBirth"
                    required
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="profileData.dateOfBirth"
                  no-title
                  @input="dateOfBirth.menu = false"
                  :locale="datePickerLocale"
                  :max="new Date().toISOString()"
                  data-cy="dateOfBirthSelector"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <p v-if="profileData.idType !== 3">{{ $t("profilePersonNum") }}</p>
              <p v-else>{{ $t("profilePersonNumBusiness") }}</p>
              <v-text-field
                outlined
                dense
                hide-details="auto"
                v-model="profileData.idNumber"
                :readonly="!idImageChecked"
                :filled="!idImageChecked"
                :rules="requiredFieldRules"
                :loading="loading"
                required
                data-cy="idNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <p v-if="profileData.idType !== 3">{{ $t("profilePersonExpiry") }}</p>
              <p v-else>{{ $t("profilePersonExpiryBusiness") }}</p>
              <v-menu
                ref="idExpiryDateMenu"
                v-model="idExpiry.menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                :disabled="disableDateOfBirth"
                :readonly="disableDateOfBirth"
                :filled="disableDateOfBirth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="profileData.idExpiry"
                    outlined
                    hide-details="auto"
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :disabled="disableDateOfBirth"
                    :filled="disableDateOfBirth"
                    :rules="idExpiryRules"
                    :loading="loading"
                    data-cy="idExpiry"
                    required
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="profileData.idExpiry"
                  no-title
                  @input="idExpiry.menu = false"
                  :min="new Date().toISOString()"
                  :locale="datePickerLocale"
                  data-cy="idExpiry"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <p>{{ $t("profilePersonCountry") }}</p>
              <v-select
                outlined
                dense
                hide-details="auto"
                v-model="profileData.countryOfIssue"
                :items="countryList"
                :item-text="$i18n.locale"
                item-value="alpha-3"
                :readonly="!idImageChecked"
                :filled="!idImageChecked"
                :rules="requiredFieldRules"
                :loading="loading"
                required
                data-cy="countryOfIssue"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="idImageChecked">
            <v-col cols="12">
              <p>{{ $t("profilePersonAddress") }}</p>
              <v-text-field
                outlined
                dense
                hide-details="auto"
                v-model="profileData.address"
                height="30px"
                required
                :rules="addressFieldRules"
                data-cy="address"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="idImageChecked">
            <v-col cols="12" class="pb-0">
              <p>{{ $t("profilePersonUserType") }}</p>
            </v-col>
            <v-radio-group v-model="profileData.paymentMethod" row style="width: 100%" class="pa-0 ma-0" mandatory>
              <v-col cols="12" md="6">
                <v-radio value="bank" @click="initializeBankAccount" :ripple="false" data-cy="bank">
                  <template v-slot:label>
                    <div class="d-flex flex-row align-center sb" style="min-height: 37px; margin: 10px 0 10px 0">
                      <span style="font-size: 14px; color: #54565a">{{ $t("profilePersonBank") }}</span>
                    </div>
                  </template>
                </v-radio>
                <v-text-field
                  class="mb-3"
                  outlined
                  dense
                  hide-details="auto"
                  :label="$t('profilePersonBankCode')"
                  v-model="profileData.bankAccount.bankCode"
                  :disabled="profileData.paymentMethod !== 'bank'"
                  :filled="profileData.paymentMethod !== 'bank'"
                  height="30px"
                  :rules="bankCodeRules"
                  data-cy="bankNumber"
                ></v-text-field>
                <v-text-field
                  class="mb-3"
                  outlined
                  dense
                  hide-details="auto"
                  :label="$t('profilePersonBankNumber')"
                  v-model="profileData.bankAccount.accountNumber"
                  :disabled="profileData.paymentMethod !== 'bank'"
                  :filled="profileData.paymentMethod !== 'bank'"
                  height="30px"
                  :rules="bankAccountNumberRules"
                  data-cy="profilePersonBankNumber"
                ></v-text-field>
                <v-text-field
                  class="mb-3"
                  outlined
                  dense
                  hide-details="auto"
                  :label="$t('profilePersonBankName')"
                  v-model="profileData.bankAccount.accountName"
                  disabled
                  :filled="profileData.paymentMethod !== 'bank'"
                  height="30px"
                  :rules="bankAccountNameRules"
                  data-cy="bankName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="enableFPS">
                <v-radio value="fps" @click="resetBankDetails" :ripple="false" data-cy="fps">
                  <template v-slot:label>
                    <div class="d-flex flex-row align-center sb">
                      <img
                        src="@/assets/zeplin/fps.png"
                        srcset="@/assets/zeplin/fps@2x.png, @/assets/zeplin/fps@3x.png"
                        class="fps"
                      />
                      <span style="font-size: 14px; color: #54565a">{{ $t("profilePersonFpsName") }}</span>
                    </div>
                  </template>
                </v-radio>
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  :placeholder="$t('profilePersonFps')"
                  v-model="profileData.fpsNumber"
                  :disabled="profileData.paymentMethod !== 'fps'"
                  :filled="profileData.paymentMethod !== 'fps'"
                  height="30px"
                  :rules="fpsRules"
                  data-cy="fpsNumber"
                ></v-text-field>
                <p class="mt-1" style="font-size: 12px; color: #7e8085">
                  {{ $t("profilePersonFpsSign") }}
                </p>
              </v-col>
            </v-radio-group>
          </v-row>
          <div style="height: 40vh; width: 100%" v-if="!idImageChecked"></div>
        </v-container>
      </v-form>
      <div class="buttonLayout">
        <v-btn
          rounded
          color="primary"
          outlined
          large=""
          depressed
          :width="$screen.width > 900 ? '150px' : 'auto'"
          class="mx-3"
          @click="backToBasic"
          data-cy="back"
          :ripple="false"
          ><span style="font-weight: 600">{{ $t("profilePersonBack") }} </span></v-btn
        >
        <v-btn
          rounded
          color="primary"
          large=""
          depressed
          :width="$screen.width > 900 ? '150px' : 'auto'"
          @click="submitForm"
          :disabled="!allowSubmit"
          :loading="submitLoading"
          :ripple="false"
          data-cy="submit"
          ><span style="font-weight: 600">{{ $t("profilePersonConfirm") }} </span></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import countryList from "@/data/countryList";
import { checkIdImageVision, uploadProtectedFileToBFS } from "../../services/fileService";
import config from "../../config";
import MessageBox from "../Common/MessageBox.vue";
import { updatePublicRoleInfo, updateUserInfo, userSignIn } from "../../services/authService";
import ConfirmDlg from "@/components/Platform/ConfirmDlg.vue";
export default {
  components: { MessageBox, ConfirmDlg },
  data: (vm) => ({
    updatingInfo: true,
    profileData: {
      idImage: null,
      idType: null,
      idName: null,
      dateOfBirth: null,
      idNumber: null,
      countryOfIssue: null,
      address: null,
      paymentMethod: null,
      paypalNumber: null,
      fpsNumber: null,
      bankAccount: {
        bankCode: null,
        accountNumber: null,
        accountName: null
      }
    },
    dateOfBirth: {
      menu: false
    },
    idExpiry: {
      menu: false
    },
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    loading: false,
    submitLoading: false,
    idImageChecked: false,
    newIdImage: false,
    idExpiryRules: [(v) => !(!v && vm.profileData.idType >= 2) || vm.$t("errorMsg.personalProfile.passportExpired")],
    requiredFieldRules: [(v) => !!v || !vm.idImageChecked || vm.$t("errorMsg.personalProfile.otherFields")],
    addressFieldRules: [(v) => !!v || vm.$t("errorMsg.personalProfile.addressEmpty")],
    dateOfBirthRules: [
      (v) => !!v || !vm.idImageChecked || "Field is required",
      (v) => {
        if (vm.profileData.idType === 3) {
          return true;
        }
        let dob = new Date(v);
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms);
        let age = Math.abs(age_dt.getUTCFullYear() - 1970);
        return age > 18 || vm.$t("profilePersonBirthText");
      }
    ],
    fpsRules: [(v) => !!v || vm.profileData.paymentMethod !== "fps" || "FPS number is required"],
    paypalRules: [(v) => !!v || vm.profileData.paymentMethod !== "paypal" || vm.$t("errorMsg.personalProfile.paypal")],
    bankCodeRules: [
      (v) => !!v || vm.profileData.paymentMethod !== "bank" || vm.$t("errorMsg.personalProfile.bank.bankCode")
    ],
    bankAccountNumberRules: [
      (v) => !!v || vm.profileData.paymentMethod !== "bank" || vm.$t("errorMsg.personalProfile.bank.accountNumber")
    ],
    bankAccountNameRules: [
      (v) => !!v || vm.profileData.paymentMethod !== "bank" || vm.$t("errorMsg.personalProfile.bank.accountName")
    ],
    valid: false,
    password: "",
    show1: false,
    passwordDialog: false,
    passwordMessage: null,
    passwordLoading: false
  }),
  created() {
    if (this.$store.state.userProfile.roleInfo) {
      if (this.$store.state.userProfile.roleInfo.idUploadDate) {
        this.updatingInfo = false;
      }
    }
    this.checkPaymentMethod();
  },
  methods: {
    async processFile(event) {
      this.profileData.idImage = event.target.files[0];
      this.newIdImage = true;
      this.$refs.form.resetValidation();
      if (this.profileData.idType || this.profileData.idType === 0) {
        this.profileData.idName = null;
        this.profileData.dateOfBirth = null;
        this.profileData.idNumber = null;
        this.profileData.idExpiry = null;
        this.profileData.countryOfIssue = null;
      }
    },
    async checkIdImage() {
      this.loading = true;
      this.$refs.form.resetValidation();
      this.profileData.idName = null;
      this.profileData.dateOfBirth = null;
      this.profileData.idNumber = null;
      this.profileData.idExpiry = null;
      this.profileData.countryOfIssue = null;
      if (this.profileData.idType === 3) {
        this.idImageChecked = true;
        this.loading = false;
        return;
      }
      this.$store.commit("showProgressLine", true);
      this.idImageChecked = false;
      let id = {
        file: this.profileData.idImage,
        type: this.profileData.idType
      };
      const result = await checkIdImageVision(this.$axios, config, id);
      if (result.status !== 200) {
        this.idImageChecked = true;
        this.$store.commit("showProgressLine", false);
        this.loading = false;
        this.messageBox = {
          content: this.$t("dialogMsg.personal.manualInput"),
          state: true,
          cssClass: "error",
          destination: ""
        };
      } else {
        this.idImageChecked = true;
        this.$store.commit("showProgressLine", false);
        const profileFromImage = result.data;
        try {
          if (profileFromImage.idNumber !== "") {
            this.profileData.idNumber = profileFromImage.idNumber;
          }
          if (profileFromImage.idName !== "") {
            this.profileData.idName = profileFromImage.name;
            this.profileData.bankAccount.accountName = profileFromImage.name;
          }
          let country = countryList.find((elem) => profileFromImage.countryOfIssue === elem["alpha-3"]);
          switch (this.profileData.idType) {
            case 0:
              if (profileFromImage.dateOfBirth === "") {
                throw Error();
              }
              this.profileData.dateOfBirth = this.processHyphenDateFormat(profileFromImage.dateOfBirth);
              this.profileData.countryOfIssue = "HKG";
              break;
            case 1:
              this.profileData.dateOfBirth = this.processDotDateFormat(profileFromImage.dateOfBirth);
              this.profileData.countryOfIssue = "HKG";
              break;
            case 2:
              this.profileData.dateOfBirth = new Date(profileFromImage.dateOfBirth).toISOString().substring(0, 10);
              this.profileData.idExpiry = new Date(profileFromImage.passportExpDate).toISOString().substring(0, 10);
              if (country) {
                this.profileData.countryOfIssue = country["alpha-3"];
              } else {
                throw Error();
              }
              break;
          }
          this.loading = false;
        } catch {
          this.messageBox = {
            content: this.$t("dialogMsg.personal.manualInput"),
            state: true,
            cssClass: "error",
            destination: ""
          };
          this.loading = false;
        }
      }
    },
    resetUserType(type) {
      this.profileData.idImage = null;
      this.idImageChecked = false;
      if (type === "business") {
        this.profileData.idType = 4;
      } else {
        this.profileData.idType = null;
      }
    },
    processDotDateFormat(date) {
      return new Date(date.replace(/\./g, "-")).toISOString().substring(0, 10);
    },
    processHyphenDateFormat(date) {
      const year = date.slice(6, 10);
      const month = date.slice(3, 5);
      const day = date.slice(0, 2);
      const dateString = year.concat("-").concat(month).concat("-").concat(day);

      return dateString;
    },
    translateArray(item) {
      const content = this.$t(item);
      const result = content.replace(/['"]+/g, "").replace(/['“]+/g, "").replace(/['”]+/g, "").split(",");
      return result;
    },
    async uploadIdCard() {
      const token = this.$session.get("token");
      const result = await uploadProtectedFileToBFS(this.$axios, config, this.profileData.idImage, token);
      return result;
    },
    async submitForm() {
      this.submitLoading = true;
      if (!this.profileData.idImage && this.newIdImage) {
        this.submitLoading = false;
        return;
      }
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        let roleInfo = this.$store.state.userProfile.roleInfo;
        if (this.newIdImage) {
          const result = await this.uploadIdCard();
          if (result.status !== 200) {
            if (result.status === 403) {
              if (this.isPremium) {
                if (await this.$refs.confirm.open(this.$t("dialogMsg.createArtwork.storage"), "")) {
                  this.$router.push("/platform/premium").catch((e) => e);
                }
              } else {
                if (await this.$refs.confirm.open(this.$t("dialogMsg.createArtwork.upgrade"), "")) {
                  this.$router.push("/platform/premium").catch((e) => e);
                }
              }
            } else {
              this.messageBox = {
                content: `Cannot upload ID image to blockchain. ${result.data.message}`,
                state: true,
                cssClass: "error",
                destination: ""
              };
              this.loading = false;
            }
            this.submitLoading = false;
            return;
          }
          roleInfo.idPhoto = result.data;
        }

        roleInfo.idType = this.profileData.idType;
        roleInfo.idNumber = this.profileData.idNumber;
        roleInfo.idExpiry = new Date(this.profileData.idExpiry).getTime();
        roleInfo.countryOfIssue = this.profileData.countryOfIssue;
        roleInfo.birthday = new Date(this.profileData.dateOfBirth).getTime();
        roleInfo.idName = this.profileData.idName;
        roleInfo.idUploadDate = new Date().getTime();
        roleInfo.birthday = this.profileData.dateOfBirth;
        roleInfo.address = this.profileData.address;

        if (this.profileData.paymentMethod === "paypal") {
          roleInfo.paypalNumber = this.profileData.paypalNumber;
          delete roleInfo.fpsAccount;
        } else if (this.profileData.paymentMethod === "fps") {
          roleInfo.fpsAccount = this.profileData.fpsNumber;
          delete roleInfo.paypalNumber;
        } else if (this.profileData.paymentMethod === "bank") {
          roleInfo.bankAccount = this.profileData.bankAccount;
          delete roleInfo.fpsAccount;
        }
        const payload = {
          roleInfo: JSON.stringify(roleInfo)
        };

        const token = this.$session.get("token");
        const updateUserInfoResult = await updateUserInfo(this.$axios, config, payload, token);

        if (updateUserInfoResult.status !== 200) {
          this.messageBox = {
            content: `Cannot update user info. ${updateUserInfoResult.data.msg}`,
            state: true,
            cssClass: "error",
            destination: ""
          };
          this.submitLoading = false;
          return;
        }

        let publicRoleInfo = this.$store.state.userProfile.publicRoleInfo;
        publicRoleInfo.idName = this.profileData.idName;
        publicRoleInfo.idUploadDate = new Date().getTime();
        publicRoleInfo.countryOfIssue = this.profileData.countryOfIssue;

        const updatePublicRoleInfoResult = await updatePublicRoleInfo(this.$axios, config, publicRoleInfo, token);
        if (updatePublicRoleInfoResult.status === 200) {
          this.messageBox = {
            content: this.$t("dialogMsg.profile.success"),
            state: true,
            cssClass: "message",
            destination: "",
            confirmEvent: "confirmUpdateSuccess"
          };
          this.submitLoading = false;
        } else {
          this.messageBox = {
            content: `Cannot update user info. ${updatePublicRoleInfoResult.data.msg}`,
            state: true,
            cssClass: "error",
            destination: ""
          };
          this.submitLoading = false;
          return;
        }
      } else {
        this.submitLoading = false;
      }
    },
    backToBasic() {
      let redirect = localStorage.getItem("redirect");
      if (redirect) {
        this.$router.push(redirect);
        localStorage.removeItem("redirect");
      }
      if (this.$store.state.userProfile.roleInfo.idUploadDate) {
        this.updatingInfo = false;
      } else {
        this.$store.commit("profilePageChange", 0);
      }
    },
    paypalSignup() {
      const redirect = (to) => {
        var windowReference = window.open();
        const getUrl = async (to) => {
          return to;
        };
        getUrl(to).then(function (url) {
          windowReference.location = url;
        });
      };
      if (this.$i18n.locale === "zhHans") {
        redirect("https://www.paypal.com/c2/webapps/mpp/home");
      } else if (this.$i18n.locale === "zhHant") {
        redirect("https://www.paypal.com/hk/webapps/mpp/home?locale.x=zh_HK");
      } else {
        redirect("https://www.paypal.com/");
      }
    },
    getCountryName(alpha) {
      let country = this.countryList.find((country) => {
        return country["alpha-3"] === alpha;
      });
      if (country) {
        return country[this.$i18n.locale];
      } else {
        return "";
      }
    },
    asterisk(char, splitChar, start, end) {
      let array = char.split(splitChar);
      var newValue = "";
      if (!end) {
        end = array.length;
      }
      for (var i = 0; i < array.length; i++) {
        if (i < start || i > end) {
          newValue += array[i];
          continue;
        } else {
          newValue += array[i].replace(/[^\s]/g, "*");
        }
      }
      return newValue;
    },
    async enterChangeDetails() {
      this.passwordLoading = true;
      const result = await userSignIn(this.$axios, config, this.email, this.password);
      if (result.status === 200) {
        this.updatingInfo = true;
        this.passwordDialog = false;
        this.passwordMessage = null;
        let roleInfo = this.$store.state.userProfile.roleInfo;
        if (roleInfo.idPhoto.fileDescriptor) {
          this.idImageChecked = true;
          this.profileData.idType = roleInfo.idType;

          this.profileData.idNumber = roleInfo.idNumber;
          if (roleInfo.idExpiry) {
            this.profileData.idExpiry = new Date(roleInfo.idExpiry).getTime();
          }

          this.profileData.countryOfIssue = roleInfo.countryOfIssue;
          this.profileData.dateOfBirth = roleInfo.birthday;
          this.profileData.idName = roleInfo.idName;

          this.profileData.address = roleInfo.address;
          if (roleInfo.fpsAccount) {
            this.profileData.fpsNumber = roleInfo.fpsAccount;
            this.profileData.paymentMethod = "fps";
          } else if (roleInfo.bankAccount || !roleInfo.fpsAccount) {
            this.profileData.paymentMethod = "bank";
            this.profileData.bankAccount.accountName = roleInfo.idName;
          }
        }
      } else {
        this.passwordMessage = this.$t(result.data.message);
      }
      this.password = "";
      this.passwordLoading = false;
    },
    closePasswordDialog() {
      this.passwordMessage = null;
      this.passwordDialog = false;
      this.password = "";
    },
    confirmUpdateSuccess() {
      this.updatingInfo = false;
      let redirect = localStorage.getItem("redirect");
      if (redirect) {
        this.$router.push(redirect);
        localStorage.removeItem("redirect");
      }
    },
    checkPaymentMethod() {
      if (!this.enablePaypal) {
        this.profileData.paymentMethod = "fps";
      }
      if (!this.enableFPS) {
        this.profileData.paymentMethod = "paypal";
      }
    },
    resetBankDetails() {
      this.profileData.bankAccount = {
        bankCode: null,
        accountNumber: null,
        accountName: null
      };
    },
    initializeBankAccount() {
      this.profileData.fpsNumber = null;
      this.profileData.bankAccount.accountName = this.profileData.idName;
    }
  },
  computed: {
    idTypeItems() {
      let labels = this.translateArray("profilePersonIDTypeOption");
      labels = labels.map((elem, i) => {
        let item = {};
        item.label = elem;
        item.index = i;
        return item;
      });
      labels.push({
        label: this.$t("profilePersonIDTypeOptionBusiness"),
        index: 3
      });
      return labels;
    },
    datePickerLocale() {
      if (this.$i18n.locale === "zhHans") {
        return "zh-cn";
      } else if (this.$i18n.locale === "zhHant") {
        return "zh-hk";
      } else {
        return "en";
      }
    },

    idExpiryErrors() {
      return null;
    },
    countryList() {
      return countryList;
    },
    allowSubmit() {
      if (!this.idImageChecked) {
        return false;
      }
      return true;
    },
    email() {
      return this.$store.state.userProfile.email;
    },
    enablePaypal() {
      return config.paypal;
    },
    enableFPS() {
      return config.fps;
    },
    idNameWatch() {
      return this.profileData.idName;
    },
    disableDateOfBirth() {
      return !this.idImageChecked || this.profileData.idType === 0 || this.profileData.idType === 1;
    },
    isBusiness() {
      if (this.$store.state.userProfile.roleInfo) {
        return this.$store.state.userProfile.roleInfo.idType === 3;
      } else {
        return false;
      }
    }
  },
  watch: {
    idNameWatch() {
      if (this.profileData.paymentMethod === "bank") this.profileData.bankAccount.accountName = this.idNameWatch;
    }
  }
};
</script>
